<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card crm-widget">
                <div class="card-body p-0">
                    <div class="row row-cols-xxl-2 row-cols-md-2 row-cols-1 g-0">
                        <div class="col">
                            <div class="py-4 px-3">
                                <h5 class="text-muted text-uppercase fs-13">Kepimilikan Saham 12 Bulan Terakhir
                                </h5>
                                <div class="d-flex align-items-center" v-if="meta">
                                    <div class="flex-shrink-0">
                                        <i class="ri-coin-line display-6 text-muted"></i>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h2 class="mb-0"><span class="counter-value">{{
                                                meta['total']
                                        }}</span></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="mt-3 mt-md-0 py-4 px-3">
                                <h5 class="text-muted text-uppercase fs-13">Total Kepemilikan Saham
                                </h5>
                                <div class="d-flex align-items-center" v-if="meta">
                                    <div class="flex-shrink-0">
                                        <i class="ri-coin-fill display-6 text-muted"></i>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h2 class="mb-0"><span class="counter-value">{{ meta['all'] }}</span></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header align-items-center d-flex">
                    <h4 class="card-title mb-0 flex-grow-1">Grafik Pembelian Saham (12 Bulan Terakhir)</h4>
                </div>

                <div class="card-body">
                    <BarChart :chartData="shareOverviewDataset" :options="options" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { endpoint, host } from '../../host'
import httpService from '../../services/http-service'
import { BarChart } from 'vue-chart-3';
import { Chart, registerables } from "chart.js";
import errorService from '../../services/error-service';
Chart.register(...registerables);
export default {
    components: {
        BarChart,
    },
    data() {
        return {
            load: false,
            share_overviews: null,
            meta: null,
            options: {
                // indexAxis: 'y',
                responsive: true,
                maintainAspectRatio: false
            },
            shareOverviewDataset: {
                labels: [],
                datasets: [
                    {
                        label: 'slot saham',
                        data: [],
                        backgroundColor: ['#405189'],
                    },
                ],
            },
        }
    },
    created() {
        this.getShareOverview();
    },
    methods: {
        async getShareOverview() {
            try {
                let res = await httpService.get(endpoint.share + '/overview');
                this.meta = res.data.meta;
                let list = res.data.data;
                list.forEach(element => {
                    this.shareOverviewDataset.labels.push(element['month_name']);
                    this.shareOverviewDataset.datasets[0].data.push(element['total']);
                });
            } catch (error) {
                errorService.displayError(error);
            }
        }
    }
}
</script>

<style>
</style>