<template>
  <div class="page-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="
              page-title-box
              d-sm-flex
              align-items-center
              justify-content-between

            ">
                        <h4 class="mb-sm-0">Sahamku</h4>

                        <div class="page-title-right">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item active">Sahamku</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-12">
                </div>

                <ShareOverview/>
                
            </div>
        </div>
    </div>
</template>

<script>
import ShareOverview from './ShareOverview.vue';
export default {
    components: { ShareOverview }
}
</script>

<style>

</style>